import React, { Component } from "react"
// import Index from "../../components/about/index"
import Social from "../../components/Social";

//TODO description Home 
const heroContent = {
  heroImage: "img/hero/dark.jpg",
  heroMobileImage: "img-mobile",
  heroTitleName: "Soliman",
  heroDesignation: "Android developer",
  heroDescriptions: `Over 4 years of experience in software development delivering and maintaining high scale mobile apps in various industries.
  Developed and maintained more than 10 apps used by millions of users in the world.
  I'm always seeking for sharpening my technical and communication skills and take it to the next level.
  I'm passionate about participating in the open-sourced applications, developing my personality, reading technical articles, and learning new technologies.
  `,
  heroBtn: "more about me",
}
export default class Hero extends Component {
  constructor(props) {
    super(props);
    console.log()
  }
  render() {
    return (
      <>
        <div className="row home-details-container align-items-center">
          <div data-aos="zoom-in-down" data-aos-duration="1200"
            className="col-lg-4 bg position-fixed d-none d-lg-block"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL + heroContent.heroImage})`,
              backgroundPosition: 'center',//TODO Change Image Scale
              backgroundSize: '100% 100%'
            }}
          ></div>
          <div className="col-12 col-lg-8 offset-lg-4 home-details  text-center text-lg-start">
            <div>
              <img src={`img/hero/${heroContent.heroMobileImage}.jpg`} className="img-fluid main-img-mobile d-sm-block d-lg-none" alt="hero man" />
              <h1 className="text-uppercase poppins-font">
                I'm {heroContent.heroTitleName}.<span className="herotitle">{heroContent.heroDesignation}</span>
              </h1>
              <p data-aos="zoom-in" data-aos-duration="1200" className="open-sans-font">{heroContent.heroDescriptions}</p>

              <div data-aos="fade-left" data-aos-duration="1200" className="contact social-home">
                <div className="row">
                  <div className="col-12">
                    <Social />
                    {/* End Social */}
                  </div>
                </div>
              </div>
              <div className="aboutme-button">
                <button onClick={() => { this.props.showAboutMY() }} className="button">
                  <span className="button-text">{heroContent.heroBtn}</span>
                  <span className="button-icon fa fa-arrow-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* End home-details-container */}
      </>
    )
  }
}
