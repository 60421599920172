import React, { Component } from "react"
import { selectedBlog } from "../Blog"
import Axios from "axios";


export default class ModalOneBlogContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: ""
    };
    this.blogContent();
  }
  /**
   * blog content from html file
   */
  blogContent() {
    if (selectedBlog.content) {
      Axios(`${process.env.PUBLIC_URL}/img/blog/content/${selectedBlog.content}.html`)
        .then(res => {
          this.setState({
            content: res.data
          })
        });
    }
  }

  render() {
    return (
      //  Article Starts
      <article>
        <div className="title-section text-left text-sm-center">
          <h1>
            Post <span>Details</span>
          </h1>
          <span className="title-bg">posts</span>
        </div>
        {/* Meta Starts */}

        <div className="meta open-sans-font">
          <span>
            <i className="fa fa-user"></i> Mohamed Soliman
          </span>
          <span className="date">
            <i className="fa fa-calendar"></i>{selectedBlog.date}
          </span>
          <span>
            <i className="fa fa-tags"></i> {selectedBlog.tags}
          </span>
        </div>
        {/* Meta Ends */}
        {/* Article Content Starts */}

        <h1>{selectedBlog.blogHeader}</h1>
        
        {/*  TODO
        <img src="img/blog/blog-post-1.jpg" className="img-fluid" alt="Blog" />*/}
        <div className="blog-excerpt open-sans-font pb-5">
          {/*
          <p>
            {selectedBlog.description}
          </p>
          */}
          <div dangerouslySetInnerHTML={{ __html: `${this.state.content}` }}></div>
        </div>
        {/* Article Content Ends */}
      </article>
      // Article Ends
    );
  };
};
