import React from "react"

const skillsContent = [
  { skillName: "Kotlin", img:"img/skills/mobile/kotlin.png"},
  { skillName: "Android", img:"img/skills/mobile/Android.png"},
  { skillName: "bitrise", img:"img/skills/mobile/bitrise.png"},
  { skillName: "Java", img:"img/skills/mobile/java.png"},
  { skillName: "Firebase", img:"img/skills/mobile/firebase.png"},
  { skillName: "google play", img:"img/skills/mobile/googleplay.png"},
  { skillName: "My Sql", img:"img/skills/mobile/mysql.png"},
  { skillName: "sql server", img:"img/skills/mobile/sqlserver.png"},
  { skillName: "sqlite", img:"img/skills/mobile/sqlite.png"},
  { skillName: "RX js", img:"img/skills/mobile/rx.png"},
  { skillName: "ktor", img:"img/skills/mobile/ktor.png"},
  { skillName: "GoogleCloud", img:"img/skills/mobile/cloud.png"},
  { skillName: "git hub", img:"img/skills/mobile/git.png"},
  { skillName: "jit pack", img:"img/skills/mobile/jitpack.svg"},
  { skillName: "gitguardian", img:"img/skills/mobile/gitgu.png"},
  { skillName: "vs code", img:"img/skills/mobile/vscode.png"},
  { skillName: "postman", img:"img/skills/mobile/postman.png"},
  { skillName: "jira", img:"img/skills/mobile/jira.svg"},
  { skillName: "eclipse", img:"img/skills/mobile/eclipse.png"},
  { skillName: "IntelliJ", img:"img/skills/mobile/IntelliJ.png"},

]

const Skills = () => {
  return (
    <>
      <ul data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="0" className="row grid justify-content-center skills-ul">
        {skillsContent.map((val, i) => (
          <li key={i}>
            <div className="col-6 col-md-3 justify-content-center">
              <div className="img-skill">
                <img src={val.img} alt={val.skillName} />
                <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">{val.skillName}</h6>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Skills
