import React from "react";

const educationContent = [
  {
    year: "Mars 2020-May 2020",
    degree: "Intern, ",
    institute: "Grossify Business",
    details: `Learned Android Application Development .
    Gained knowledge about Server Communication, databinding, API, etc`,
  },
  {
    year: "2017-2021",
    degree: "BACHELOR DEGREE ",
    institute: "Ain Shams university",
    details: `Graduated from Ain Shams University, Department of Computer Science, with a grade of "very good" and "excellent" graduation project`,
  },
];

const Education = () => {
  return (
    <ul>
      {educationContent.map((val, i) => (
        <li data-aos="zoom-in" data-aos-duration="1200" key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.degree}
            <span className="place open-sans-font">{val.institute}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Education;
