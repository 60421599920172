import React from "react"

const skillsContent = [
    { skillName: "html", img:"img/skills/others/html.png"},
    { skillName: "css", img:"img/skills/others/css.png"},
    { skillName: "js", img:"img/skills/others/js.png"},
    { skillName: "xd", img:"img/skills/others/xd.png"},
    { skillName: "r studio", img:"img/skills/others/rstudio.png"},
    { skillName: ".net core", img:"img/skills/others/dotnet.png"},
    { skillName: "vs ide", img:"img/skills/others/vs.png"},
    { skillName: "matlab", img:"img/skills/others/matlab.png"},
    { skillName: "bootstrap", img:"img/skills/others/bootstrap.png"},
    { skillName: "python", img:"img/skills/others/py.png"},
    { skillName: "wpf", img:"img/skills/others/wpf.png"},
    { skillName: "pycharm", img:"img/skills/others/pycharm.png"},
    { skillName: "ps", img:"img/skills/others/ps.png"},
    { skillName: "jquery", img:"img/skills/others/jquery.png"},
    { skillName: "spyder", img:"img/skills/others/spyder.png"},
    { skillName: "oracle", img:"img/skills/others/oracle.png"},
    { skillName: "wordpress", img:"img/skills/others/wordpress.png"},
    { skillName: "jetbrains", img:"img/skills/others/jetbrains.png"},
    { skillName: "anaconda", img:"img/skills/others/anaconda.png"},
    { skillName: "blender", img:"img/skills/others/blender.png"},
    { skillName: "autodesk", img:"img/skills/others/autodesk.png"},
    { skillName: "jupyter", img:"img/skills/others/jupyter.png"},

]

const Others = () => {
  return (
    <>
      <ul data-aos="zoom-in" data-aos-duration="1200" data-aos-delay="0" className="row grid justify-content-center skills-ul">
        {skillsContent.map((val, i) => (
            <li key={i}>
            <div className="col-6 col-md-3 justify-content-center">
              <div className="img-skill">
                <img src={val.img} alt={val.skillName} />
                <h6 className="text-uppercase open-sans-font text-center mt-2 mt-sm-4">{val.skillName}</h6>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}

export default Others
