import React, { Component } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Modal from "react-modal"
import ModalOneContent from "./modal/ModalOneContent"


const projects = [
  {
    projectName: "Aladdin",
    img: "img/projects/aladin.jpg",
    langages: "Android, Java",
    previewUrl: "https://play.google.com/store/apps/details?id=delivery.aladdin.customer",
    preview: "Google play",
    type: "mobile",
    Client: "Aladdin",
  },
  {
    projectName: "swiftee",
    img: "img/projects/swiftee.jpg",
    langages: "Android, Kotlin",
    previewUrl: "https://play.google.com/store/apps/details?id=com.checkoutbyswiftee.customer",
    preview: "Google play",
    type: "mobile",
    Client: "Swiftee",
  },
  {
    projectName: "Drink N GO",
    img: "img/projects/DrinkNGO.jpg",
    langages: "Android, Kotlin",
    previewUrl: "https://play.google.com/store/apps/details?id=com.drinkngo.customer",
    preview: "Google play",
    type: "mobile",
    Client: "Drink N GO",
  },
  {
    projectName: "Qichta App",
    img: "img/projects/qichta.jpg",
    langages: "Kotlin, Java",
    previewUrl: "https://play.google.com/store/apps/details?id=com.qichta.customer&hl=en&gl=US",
    preview: "Google play",
    type: "mobile",
    Client: "Qichta",
  },
  {
    projectName: "zeew",
    img: "img/projects/zeew.jpg",
    langages: "Android, Kotlin",
    previewUrl: "https://play.google.com/store/apps/details?id=com.foodzeew.customer",
    preview: "Google play",
    type: "mobile",
    Client: "Zeew",
  },
  {
    projectName: "Medicine Reminder",
    img: "img/projects/Medicine Reminder.jpg",
    langages: "Android, Java",
    previewUrl: "https://github.com/MohamedSoliman1999/Medicine-In-Take-Reminder-and-Monitor",
    preview: "Github",
    type: "mobile",
    Client: "Personal",
    youtube: "-f5hcl-G26o"
  },
  {
    projectName: "Call-Me",
    img: "img/projects/callme.jpg",
    langages: "Android, Kotlin",
    previewUrl: "https://drive.google.com/file/d/1wRj0GdJBKt_jAlJC7MQcKBHLdl_XY0N_/view?usp=share_link",
    preview: "APK",
    type: "mobile",
    Client: "Personal ",
    youtube: "FOhCsBIi3n4"
  },
  {
    projectName: "Radio-App",
    img: "img/projects/radio_cover.jpg",
    langages: "Android, Kotlin",
    previewUrl: "https://play.google.com/store/apps/details?id=org.Soli.Radio_App",
    preview: "Google play",
    type: "mobile",
    Client: "Personal ",
    youtube: "rc0DI62BDuY"
  },
  {
    projectName: "travisit business",
    img: "img/projects/travisit business.jpg",
    langages: "Android, Java",
    previewUrl: "https://github.com/MohamedSoliman1999/TravisitBusiness",
    preview: "Github",
    type: "mobile",
    Client: "Travisit",
  },
  {
    projectName: "Food app",
    img: "img/projects/food app.jpg",
    langages: "Android, Java",
    previewUrl: "https://github.com/MohamedSoliman1999/Online-Food-App",
    preview: "Github",
    type: "mobile",
    Client: "Personal ",
    youtube: "KhE4LJOMBmQ"
  },
  {
    projectName: "be close",
    img: "img/projects/beClose.jpg",
    langages: "Android, Kotlin",
    previewUrl: "https://github.com/MohamedSoliman1999/BeClose",
    preview: "Github",
    type: "mobile",
    Client: "Personal",
  },
  {
    projectName: "travisit",
    img: "img/projects/travisit.jpg",
    langages: "Android, Java",
    previewUrl: "https://github.com/MohamedSoliman1999/Travisit",
    preview: "Github",
    type: "mobile",
    Client: "Travisit",
  },
  {
    projectName: "Bike Lovers",
    img: "img/projects/webBikeLovers.jpg",
    langages: "WordPress",
    previewUrl: "https://www.youtube.com/watch?v=GV5Hil4u5Ys&t=69s",
    preview: "Demo",
    type: "website",
    Client: "Bike Lovers",
  },
  {
    projectName: "Pizza",
    img: "img/projects/webPizza.jpg",
    langages: "WordPress",
    previewUrl: "https://www.youtube.com/watch?v=GV5Hil4u5Ys&list=PLi8kTzzQgMzvKA4UXeAiBdNnnIGMncbzI&index=1",
    preview: "Demo",
    type: "website",
    Client: "Restarant",
    youtube: "GV5Hil4u5Ys&list=PLi8kTzzQgMzvKA4UXeAiBdNnnIGMncbzI&index=1"
  },
  {
    projectName: "Shop",
    img: "img/projects/webShop.jpg",
    langages: "HTML, CSS, JS, Bootstrap",
    previewUrl: "https://www.youtube.com/watch?v=oJ_ucgGXAsk&list=PLi8kTzzQgMzvKA4UXeAiBdNnnIGMncbzI&index=3",
    preview: "Youtube",
    type: "website",
    Client: "Personal",
    youtube: "oJ_ucgGXAsk&list=PLi8kTzzQgMzvKA4UXeAiBdNnnIGMncbzI&index=3"
  },
  {
    projectName: "Desktop Store Managment",
    img: "img/projects/DesktopStoreManagment.jpg",
    langages: "C#, Windows forms, Oracle DB, Cristal report",
    previewUrl: "https://www.youtube.com/watch?v=l2BoC6KX2AQ&list=PLi8kTzzQgMzuILAc8rpGAarddwA3mHfzd",
    preview: "Demo",
    type: "desktop",
    Client: "Personal",
    youtube: "l2BoC6KX2AQ&list=PLi8kTzzQgMzuILAc8rpGAarddwA3mHfzd"
  },
]

let selectedProject = {};


export default class Portfolio extends Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
  }
  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
  };
  toggleModalOne = (val) => {

    if (!this.state.show) { selectedProject = val; this.showModal(); } else {
      this.hideModal();
    }
  }
  projectCard = (val, i) => {
    return (<li data-aos="fade-right" data-aos-duration="1200" key={i}>
      <figure onClick={() => { this.toggleModalOne(val) }}>
        <img src={val.img} alt={val.projectName} />
        <div className="hover-content-wrapper portfolio-hover">
          <span className="content-title">{val.projectName}</span>
        </div>
      </figure>

      {/* Start ModalOneContent */}
      <Modal
        isOpen={this.state.show}
        onRequestClose={() => { this.toggleModalOne(val) }}
        contentLabel="My dialog"
        className="custom-modal dark"
        overlayClassName="custom-overlay dark"
        closeTimeoutMS={500}
      >
        <div>
          <button className="close-modal" onClick={() => { this.toggleModalOne(val) }}>
            <img src="/img/cancel.svg" alt="close icon" />
          </button>
          {/* End close icon */}

          <div className="box_inner portfolio">
            <ModalOneContent />
          </div>
        </div>
      </Modal>
      {/* End  ModalOneContent */}
    </li>)
  }
  render() {
    return (
      <Tabs>
        <TabList className="portfolio-tab-list">
          <Tab>ALL</Tab>
          <Tab>MOBILE</Tab>
          <Tab>WEB</Tab>
          <Tab>WINDOWS APP</Tab>
        </TabList>
        <TabPanel className="portfolio-tab-content">
          <ul className="row grid justify-content-center">
            {projects.map((val, i) => (
              this.projectCard(val, i)
            ))}
          </ul>
        </TabPanel>
        {/* End All Project */}

        <TabPanel className="portfolio-tab-content">
          <ul className="row grid justify-content-center">
            {projects.filter(item => item.type === 'mobile').map((val, i) => (
              this.projectCard(val, i)
            ))}
          </ul>
        </TabPanel>
        {/* End mobile Project */}

        <TabPanel className="portfolio-tab-content">
          <ul className="row grid justify-content-center">
            {projects.filter(item => item.type === 'website').map((val, i) => (
              this.projectCard(val, i)
            ))}
          </ul>
        </TabPanel>
        {/* End web Project */}

        <TabPanel className="portfolio-tab-content">
          <ul className="row grid justify-content-center">
            {projects.filter(item => item.type === 'desktop').map((val, i) => (
              this.projectCard(val, i)
            ))}
          </ul>
        </TabPanel>
        {/* End desktop Project*/}
      </Tabs>
    )
  }
}
export {
  selectedProject
}