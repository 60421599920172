import React, { Component } from "react"
import Achievements from "./Achievements"
import Education from "./Education"
import Experience from "./Experience"
import PersonalInfo from "./PersonalInfo"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import Skills from "./Skills/Mobile"
import Others from "./Skills/Others"
import Index from "./"

export default class index1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 0
    }

  }

  render() {
    return (
      <section className="main-content ">
        <div className="container">
          <div className="row">
            {/* Personal Info Starts */}

            <div className="col-xl-6 col-lg-5 col-12">
              <div className="row">
                <div className="col-12">
                  <h3 className="text-uppercase custom-title mb-0 ft-wt-600">personal infos</h3>
                </div>
                {/* End .col */}

                <div className="col-12 d-block d-sm-none">
                  <img src="img/hero/img-mobile.jpg" className="img-fluid main-img-mobile" alt="about avatar" />
                </div>
                {/* image for mobile menu */}

                <div className="col-12">
                  <PersonalInfo />
                </div>
                {/* End personal info */}

                <div className="col-12 mt-1">
                  <a className="button" href="/Mohamed Soliman.pdf" download>
                    <span className="button-text">Download CV</span>
                    <span className="button-icon fa fa-download"></span>
                  </a>
                </div>
                {/* End download button */}
              </div>
            </div>
            {/*  Personal Info Ends */}

            {/*  Boxes Starts */}
            <div className="col-xl-6 col-lg-7 col-12 mt-5 mt-lg-0">
              <Achievements />
            </div>
            {/* Achievements Ends */}
          </div>
          {/* End .row */}
          <hr className="separator" />
          {/* Skills Starts */}
          <div className="row">
            {/* <div className="col-12">
            <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
              Skills & Tools
            </h3>
          </div> */}
            <div data-aos="fade-up" data-aos-duration="1000">
              <div className="title-section text-left text-sm-center skills-tools">
                <h1>
                  Skills <span>& Tools</span>
                </h1>
                {/* <span className="title-bg">Skills</span> */}
              </div>
              {/* End title */}
            </div>
          </div>
          <Tabs onSelect={tabIndex => this.setState({ tabIndex })} style={{ display: 'flex', flexDirection: 'column' }}>
            <TabList className="tab-list-skills mb-8 mx-auto">
              <Tab className={' tab-skills px-6 ' + (this.state.tabIndex === 0 ? ' selected-tab-skills' : '')}>Mobile</Tab>
              <Tab className={' tab-skills px-6 ' + (this.state.tabIndex === 1 ? ' selected-tab-skills' : '')}>Others</Tab>
            </TabList>
            <div className="portfolio-tab-content">
              <TabPanel className="tappanel-skills">
                <Skills />
              </TabPanel>
              <TabPanel className="tappanel-skills">
                <Others />
              </TabPanel>
            </div>
          </Tabs>
          {/* Skills Ends */}

          <hr className="separator mt-1" />

          {/* Experience & Education Starts */}
          <div className="row">
            <div className="col-12">
              <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
                Experience <span>&</span> Education
              </h3>
            </div>
            <div className="col-lg-6 m-15px-tb">
              <div className="resume-box">
                <Experience />
              </div>
            </div>
            <div className="col-lg-6 m-15px-tb">
              <div className="resume-box">
                <Education />
              </div>
            </div>
          </div>
          {/*  Experience & Education Ends */}
        </div>
      </section>
    )
  }
}

const index2 = () => {
  return (
    <div className="about">
      <div data-aos="fade-up" data-aos-duration="1200">
        <div className="title-section text-left text-sm-center">
          <h1>
            ABOUT <span>ME</span>
          </h1>
          <span className="title-bg">Resume</span>
        </div>
        {/* End title */}
        <Index />
      </div>
    </div>
  )
}
export { index2 }
