import React from "react";

const experienceContent = [
  {
    year: "May 2021-Mars 2023",
    position: "Mobile Developer",
    compnayName: "Zeew",
    details: `Fixing various Bugs using kotlin and java.
              Created and deploy new mobile apps.
              Worked on refactoring old java code to kotlin code.
              Build CI/CD pipeline`,
  },
  {
    year: "January 2021-Mars 2021",
    position: "Android developer",
    compnayName: "Remotely, Travisit",
    details: `Optimizing the Clean architecture base classes, including Usecase, Error handling, and Rxjava stream.
    Developed new apps and maintained old apps`,
  },
  {
    year: "September 2020- January 2021",
    position: "Android developer",
    compnayName: "WinWin Solution",
    details: `Developed Android Apps. 
    Worked on various projects, with strong experience using Java & Koltin.
    Worked with the team to ship many features along.`,
  },
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li data-aos="zoom-in" data-aos-duration="1200" key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font-with-multiline">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
