import React, { useEffect } from "react";
import Routes from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  // this for animation
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <div className="mouse-cursor">
        <AnimatedCursor
          innerSize={8}
          outerSize={44}
          color="255, 160, 1"  //TODO change color of Coursur "RGB"
          outerAlpha={0.3}
          innerScale={0.7}
          outerScale={1.2}
        />
      </div>
      <ScrollToTop />
      <Routes />
    </>
  );
};

export default App;
