import React from "react"

const SocialShare = [
  { iconName: "fa fa-facebook", link: "https://www.facebook.com/mohamed.Soliman.co" },
  { iconName: "fa fa-linkedin", link: "https://www.linkedin.com/in/mohamed-ismail-soliman" },
  { iconName: "fa fa-github", link: "https://github.com/MohamedSoliman1999" },
  { iconName: "fa fa-youtube", link: "https://www.youtube.com/channel/UCFh6MxNVel0yGLSNbBJZhOA" },
  { iconName: "fa fa-whatsapp", link: "https://wa.me/+201024863097?text=Hello" },
]

const Social = () => {
  return (
    <ul className="social list-unstyled pt-1 mb-5">
      {SocialShare.map((val, i) => (
        <li key={i}>
          <a href={val.link} target="_blank" rel="noreferrer">
            <i className={val.iconName}></i>
          </a>
        </li>
      ))}
    </ul>
  )
}

export default Social
