import React, { useState } from "react";
import Modal from "react-modal";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import ModalOneBlogContent from "./modal/ModalOneBlogContent";

Modal.setAppElement("#root");

const blogs = [
  {
    blogHeader: "Permissions",
    description: "Today I am going to talk about Permissions In Programming so Gather your focus.",
    img: "img/blog/blog-post-1.jpg",
    date: "9 Feb 2021",
    tags: " Java, Kotlin, Mobile",
    quote: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiam nonumy eirmod tempor invidunt ut labore ",
    type: "article",
    content: "blog-post-1"
  },
  {
    blogHeader: "Design Patterns in Android with Kotlin",
    description: "Improve the scalability and maintainability of your applications by implementing a variety of design patterns using Kotlin",
    img: "img/blog/design-patter.jpg",
    date: "9 Feb 2021",
    tags: " Java, Kotlin, Mobile",
    quote: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiam nonumy eirmod tempor invidunt ut labore ",
    type: "article",
    content: "design-pattern"
  },
  {
    blogHeader: "Android ViewModel",
    description: "ViewModel is designed to store and manage UI-related data in a lifecycle conscious way.",
    img: "img/blog/view-model.jpg",
    date: "9 Feb 2021",
    tags: " Java, Kotlin, Mobile",
    quote: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiam nonumy eirmod tempor invidunt ut labore ",
    type: "article",
    content: "view-model"
  },
  /*{
    blogHeader: "Permissions",
    description: "Today I am going to talk about Permissions In Programming so Gather your focus.",
    img: "img/blog/blog-post-1.jpg",
    date: "9 Feb 2021",
    tags: " Java, Kotlin, Mobile",
    quote: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, seddiam nonumy eirmod tempor invidunt ut labore ",
    type: "article",
    content: "blog-post-1"
  },*/
]
let selectedBlog = {};

const Blog = () => {
  const [isOpen, setIsOpen] = useState(false);


  function toggleModalOne(val) {
    if (!isOpen) { selectedBlog = val; }
    setIsOpen(!isOpen);
  }

  return (
    <>
      <Tabs>
        <TabList className="portfolio-tab-list blog-tab-list">
          {/* TODOL Add Tap*/}
          <Tab>ALL</Tab>
          <Tab>Article</Tab>
          {/* <Tab>Favorite</Tab> */}
        </TabList>
        <TabPanel className="row grid justify-content-center">
          {/* Blog Starts */}
          {blogs.map((val, i) => (
            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="0" key={i}>
              <article className="post-container" onClick={() => { toggleModalOne(val) }}>
                <div className="post-thumb">
                  <div className="d-block position-relative overflow-hidden">
                    <img
                      src={val.img}
                      className="img-fluid"
                      alt="Blog Post"
                    />
                  </div>
                </div>
                {/* End .thumb */}
                <div className="post-content">
                  <div className="entry-header">
                    <h3>{val.blogHeader}</h3>
                  </div>
                  <div className="entry-content open-sans-font">
                    <p className="blog-description">
                      {val.description}
                    </p>
                  </div>
                </div>
                {/* End .post-content */}
              </article>
              {/* Start ModalOneBlogContent */}
              <Modal
                isOpen={isOpen}
                onRequestClose={() => { toggleModalOne(val) }}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={() => { toggleModalOne(val) }}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner blog-post">
                    <ModalOneBlogContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneBlogContent */}
            </div>
          ))}
          {/*  Blog Ends */}

        </TabPanel>
        <TabPanel className="row grid justify-content-center">
          {/* Blog Starts */}
          {blogs.filter(item => item.type === 'article').map((val, i) => (
            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="0" key={i}>
              <article className="post-container" onClick={() => { toggleModalOne(val) }}>
                <div className="post-thumb">
                  <div className="d-block position-relative overflow-hidden">
                    <img
                      src={val.img}
                      className="img-fluid"
                      alt="Blog Post"
                    />
                  </div>
                </div>
                {/* End .thumb */}
                <div className="post-content">
                  <div className="entry-header">
                    <h3>{val.blogHeader}</h3>
                  </div>
                  <div className="entry-content open-sans-font">
                    <p className="blog-description">
                      {val.description}
                    </p>
                  </div>
                </div>
                {/* End .post-content */}
              </article>
              {/* Start ModalOneBlogContent */}
              <Modal
                isOpen={isOpen}
                onRequestClose={() => { toggleModalOne(val) }}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={() => { toggleModalOne(val) }}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner blog-post">
                    <ModalOneBlogContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneBlogContent */}
            </div>
          ))}
          {/*  Blog Ends */}

        </TabPanel>
        <TabPanel className="row grid justify-content-center">
          {/* Blog Starts */}
          {blogs.filter(item => item.type === "favorite").map((val, i) => (
            <div className="col-12 col-md-6 col-lg-6 col-xl-4 mb-30" data-aos="fade-right" data-aos-duration="1200" data-aos-delay="0" key={i}>
              <article className="post-container" onClick={() => { toggleModalOne(val) }}>
                <div className="post-thumb">
                  <div className="d-block position-relative overflow-hidden">
                    <img
                      src={val.img}
                      className="img-fluid"
                      alt="Blog Post"
                    />
                  </div>
                </div>
                {/* End .thumb */}
                <div className="post-content">
                  <div className="entry-header">
                    <h3>{val.blogHeader}</h3>
                  </div>
                  <div className="entry-content open-sans-font">
                    <p className="blog-description">
                      {val.description}
                    </p>
                  </div>
                </div>
                {/* End .post-content */}
              </article>
              {/* Start ModalOneBlogContent */}
              <Modal
                isOpen={isOpen}
                onRequestClose={() => { toggleModalOne(val) }}
                contentLabel="My dialog"
                className="custom-modal dark"
                overlayClassName="custom-overlay dark"
                closeTimeoutMS={500}
              >
                <div>
                  <button className="close-modal" onClick={() => { toggleModalOne(val) }}>
                    <img src="/img/cancel.svg" alt="close icon" />
                  </button>
                  {/* End close icon */}

                  <div className="box_inner blog-post">
                    <ModalOneBlogContent />
                  </div>
                </div>
              </Modal>
              {/* End  ModalOneBlogContent */}
            </div>
          ))}
          {/*  Blog Ends */}

        </TabPanel>
      </Tabs>

    </>
  );
};

export default Blog;
export {
  selectedBlog
}