import React from "react";

const personalInfoContent = [
  { meta: "first name", metaInfo: "Mohamed" },
  { meta: "last name", metaInfo: "Soliman" },
  { meta: "Age", metaInfo: "24 Years" },
  { meta: "Nationality", metaInfo: "Egyption" },
  { meta: "Open to Work", metaInfo: "Available" },
  { meta: "Address", metaInfo: "Cairo, Egypt" },
  { meta: "phone", metaInfo: "+201024863097" },
  { meta: "Email", metaInfo: "mohamedsoliman001122s@gmail.com" },
  //{ meta: "Skype", metaInfo: " mohamed.soliman" },
  { meta: "langages", metaInfo: "Arabic, English" },
];

const PersonalInfo = () => {
  return (
    <ul className="about-list list-unstyled open-sans-font">
      {personalInfoContent.map((val, i) => (
        <li data-aos="fade-right" data-aos-duration="1200" key={i}>
          <span className="title">{val.meta}: </span>
          <span className="value d-block d-sm-inline-block d-lg-block d-xl-inline-block">
            {val.metaInfo}
          </span>
        </li>
      ))}
    </ul>
  );
};

export default PersonalInfo;
